<template>
  <div class="base-container">
    <div class="main-wrapper">
      <div class="tag">土地交易</div>
      <div class="list_box">
        <div class="search-box">
          <div class="module-title">土地交易</div>
          <el-form :inline="true" class="demo-form-inline" size="mini">
            <el-form-item label="切换地区：">
              <el-cascader
                size="large"
                :options="areaData"
                v-model="selectedOptions"
                @change="areaHandleChange"
              >
              </el-cascader>
            </el-form-item>
          </el-form>
        </div>
        <div class="table_box">
          <div class="main_box">
              <div class="input_box" v-clickoutside="handleClose">
                  <p>项目名称：</p>
                  <el-input class="search-content" size="small" v-model="projectName" placeholder="点击输入项目名称" ></el-input>
                  <p>电子监管号：</p>
                  <el-input class="search-content" size="small" v-model="supervisionNumber" placeholder="点击输入电子监管号" ></el-input>
                  <p>土地用途：</p>
                  <el-select @change="selectChange" v-model="landUsageSuperior" placeholder="请选择">
                      <el-option
                      v-for="(item,key) in landUsageData.landUage"
                      :key="key"
                      :label="key"
                      :value="key">
                      </el-option>
                  </el-select>
                  <el-select v-model="landUsage" placeholder="请选择">
                      <el-option
                      v-for="item in landUsageList"
                      :key="item"
                      :label="item"
                      :value="item">
                      </el-option>
                  </el-select>
                  <p class="more_search" @click="getMoreSelect">更多查询条件</p>
                  <el-button class="search_btn" type="primary" size="small" @click="searchData(true)">查询</el-button>
                  <el-button class="search_btn" size="small" @click="reset">重置</el-button>
                  <div class="select_more" v-show="isMoreSelect">
                      <p class="title">更多查询条件</p>
                      <div class="input">
                          <div class="input_item">
                              <div class="item_title">项目位置</div>
                              <el-input class="search-content" size="small" placeholder="点击输入项目位置" v-model="projectAddress" ></el-input>
                          </div>
                          <div class="input_item">
                              <div class="item_title">供地方式</div>
                              <el-select v-model="landSupplyMethod" placeholder="请选择">
                                  <el-option
                                  v-for="item in landUsageData.supplyMethod"
                                  :key="item"
                                  :label="item"
                                  :value="item">
                                  </el-option>
                              </el-select>
                          </div>
                          <div class="input_item">
                              <div class="item_title">容积率</div>
                              <el-input class="search-content small_input" type="number" size="small" placeholder="最低" v-model="plotRatioUp"></el-input>
                              <p class="line">~</p>
                              <el-input class="search-content small_input" type="number" size="small" placeholder="最高" v-model="plotRatioDown"></el-input>
                          </div>
                          <div class="input_item">
                              <div class="item_title">成交价格(万)</div>
                              <el-input class="search-content small_input" type="number" size="small" placeholder="最低" v-model="salePriceStart"></el-input>
                              <p class="line">~</p>
                              <el-input class="search-content small_input" type="number" size="small" placeholder="最高" v-model="salePriceEnd"></el-input>
                          </div>
                          <div class="input_item">
                              <div class="item_title">土地级别</div>
                              <el-select v-model="landLevel" placeholder="请选择">
                                  <el-option
                                  v-for="item in landUsageData.landLevel"
                                  :key="item"
                                  :label="item"
                                  :value="item">
                                  </el-option>
                              </el-select>
                          </div>
                          <div class="input_item">
                              <div class="item_title">土地使用权人</div>
                              <el-input class="search-content" size="small" placeholder="点击输入土地使用权人" v-model="landHolder"></el-input>
                          </div>
                          <div class="input_item">
                              <div class="item_title">行业</div>
                              <el-select @change="industryChange" v-model="industryParent" placeholder="请选择">
                                  <el-option
                                  v-for="(item,key) in landUsageData.industry"
                                  :key="key"
                                  :label="key"
                                  :value="key">
                                  </el-option>
                              </el-select>
                          </div>
                          <div class="input_item">
                              <div class="item_title"></div>
                              <el-select v-model="industryCategory" placeholder="请选择">
                                  <el-option
                                  v-for="item in industryCategoryList"
                                  :key="item"
                                  :label="item"
                                  :value="item">
                                  </el-option>
                              </el-select>
                          </div>
                          <div class="input_item">
                              <div class="item_title">批准单位</div>
                              <el-input class="search-content" size="small" placeholder="点击输入批准单位" v-model="authority"></el-input>
                          </div>
                          <div class="input_item">
                              <div class="item_title">土地面积(公顷)</div>
                              <el-input class="search-content small_input" type="number" size="small" placeholder="最低" v-model="areaSizeStart"></el-input>
                              <p class="line">~</p>
                              <el-input class="search-content small_input" type="number" size="small" placeholder="最高" v-model="areaSizeEnd"></el-input>
                          </div>
                          <div class="input_item">
                              <div class="item_title">合同签订日期</div>
                          </div>
                          <div class="input_item">
                              <el-date-picker class="date_select"
                                  v-model="contractSigningDate"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  value-format="yyyy-MM-dd">
                              </el-date-picker>
                          </div>
                          <div class="input_item">
                              <div class="item_title">实际竣工时间</div>
                          </div>
                          <div class="input_item">
                              <el-date-picker class="date_select"
                                  v-model="realEndTime"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  value-format="yyyy-MM-dd">
                              </el-date-picker>
                          </div>
                          <div class="input_item">
                              <div class="item_title">实际开工时间</div>
                          </div>
                          <div class="input_item">
                              <el-date-picker class="date_select"
                                  v-model="realStartTime"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  value-format="yyyy-MM-dd">
                              </el-date-picker>
                          </div>
                          <div class="input_item">
                              <div class="item_title">约定竣工时间</div>
                          </div>
                          <div class="input_item">
                              <el-date-picker class="date_select"
                                  v-model="arrangeEndTime"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  value-format="yyyy-MM-dd">
                              </el-date-picker>
                          </div>
                          <div class="input_item">
                              <div class="item_title">约定开工时间</div>
                          </div>
                          <div class="input_item">
                              <el-date-picker class="date_select"
                                  v-model="arrangeStartTime"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  value-format="yyyy-MM-dd">
                              </el-date-picker>
                          </div>
                          <div class="input_item">
                              <div class="item_title">约定交地时间</div>
                          </div>
                          <div class="input_item">
                              <el-date-picker class="date_select"
                                  v-model="arrangeDeliveryDime"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  value-format="yyyy-MM-dd">
                              </el-date-picker>
                          </div>
                      </div>
                  </div>
                  <div class="right">
                      <i class="el-icon-setting" @click="getMoreCol"></i>
                  </div>
                  <div class="col_more" v-show="isColMore">
                      <p class="title">编辑列</p>
                      <div class="reset" @click="resetTable">重置</div>
                      <div class="select_all" @click="selectAll">全选</div>
                      <div class="select_scroll">
                          <el-table
                              ref="multipleTable"
                              :data="tagData"
                              :show-header="false"
                              @selection-change="handleSelectionChangeTag">
                              <el-table-column
                                  type="selection"
                                  width="30">
                              </el-table-column>
                              <el-table-column>
                                  <template slot-scope="scope">{{ scope.row.name }}</template>
                              </el-table-column>
                          </el-table>
                      </div>
                      <el-button class="confirm" type="primary" size="small" @click="confirm" >确定</el-button>
                  </div>
              </div>
              <div class="table-box">
                  <el-table ref="table" height="405" style="width: 100%" :data="validTableData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}"  @selection-change="handleSelectionChange">
                      <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
                      <el-table-column label="区域" width="150" :show-overflow-tooltip="true">
                          <template slot-scope="scope">
                              <span>{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.county }}</span>
                          </template>
                      </el-table-column>
                      <el-table-column prop="projectName" label="项目名称" width="160" :show-overflow-tooltip="true"></el-table-column>
                      <el-table-column prop="projectAddress" label="项目位置" width="150" :show-overflow-tooltip="true"></el-table-column>
                      <el-table-column prop="areaSize" label="土地面积(公顷)" align="right"></el-table-column>
                      <el-table-column label="约定容积率上下限" align="right" width="80">
                          <template slot-scope="scope">
                              <span>{{ scope.row.plotRatioDown }}-{{ scope.row.plotRatioUp }}</span>
                          </template>
                      </el-table-column>
                      <el-table-column prop="landUsage" label="土地用途" :show-overflow-tooltip="true" width="120"></el-table-column>
                      <el-table-column prop="landHolder" label="土地使用权人" width="160" :show-overflow-tooltip="true"></el-table-column>
                      <el-table-column prop="contractSigningDate" label="合同签订日期" :show-overflow-tooltip="true" width="135"></el-table-column>
                      <el-table-column prop="supervisionNumber" label="电子监管号" :show-overflow-tooltip="true" v-if="idArr.includes('dzjg')" width="120"></el-table-column>
                      <el-table-column prop="landSource" label="土地来源" :show-overflow-tooltip="true" v-if="idArr.includes('tdly')" width="120"></el-table-column>
                      <el-table-column prop="landSupplyMethod" label="供地方式" :show-overflow-tooltip="true" v-if="idArr.includes('gdfs')" width="100"></el-table-column>
                      <el-table-column prop="salePrice" label="成交价格（万元）" :show-overflow-tooltip="true" v-if="idArr.includes('cjjg')" width="100"></el-table-column>
                      <el-table-column prop="landLevel" label="土地级别" :show-overflow-tooltip="true" v-if="idArr.includes('tdjb')" width="100"></el-table-column>
                      <el-table-column prop="industryCategory" label="行业" :show-overflow-tooltip="true" v-if="idArr.includes('hy')" width="120"></el-table-column>
                      <el-table-column prop="authority" label="批准单位" :show-overflow-tooltip="true" v-if="idArr.includes('pzdw')" width="120"></el-table-column>
                      <el-table-column prop="realEndTime" label="实际竣工时间" :show-overflow-tooltip="true" v-if="idArr.includes('sjjg')" width="120"></el-table-column>
                      <el-table-column prop="realStartTime" label="实际开工时间" :show-overflow-tooltip="true" v-if="idArr.includes('sjkg')" width="120"></el-table-column>
                      <el-table-column prop="arrangeEndTime" label="约定竣工时间" :show-overflow-tooltip="true" v-if="idArr.includes('ydjg')" width="120"></el-table-column>
                      <el-table-column prop="arrangeStartTime" label="约定开工时间" :show-overflow-tooltip="true" v-if="idArr.includes('ydkg')" width="120"></el-table-column>
                      <el-table-column prop="arrangeDeliveryDime" label="约定交地时间" :show-overflow-tooltip="true" v-if="idArr.includes('ydjd')" width="120"></el-table-column>
                    
                  </el-table>
              </div>
              <div class="page-box">
                  <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      background
                      layout="prev,pager,next,total,sizes,jumper"
                      :page-sizes="[10, 20, 50, 100, 1000, 2000]"
                      :total="total"
                      :current-page="pageNum">
                  </el-pagination>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStore, setStore, getLocalStore } from "@/util/store";
import { mapMutations } from "vuex";
import { regionDataPlus, CodeToText, TextToCode } from "element-china-area-data"; // 地址级联选择器
const clickoutside = {
    // 初始化指令
    bind(el, binding, vnode) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};
export default {
  name: "index",
  data() {
    return {
      validTableData:[],
      projectName: "",
      company:'',
      address:'',
      areaData: [],
      selectedOptions: [],
      tableData: [],
      province:'',
      city:'',
      county:'',
      landUsageData: {},
      projectName:'',
      supervisionNumber:'',
      isMoreSelect: false,
      landUsageList: [],
      landUsageSuperior: '',
      landUsage: '',
      projectAddress: '',
      landSupplyMethod: '',
      plotRatioUp: '',
      plotRatioDown: '',
      salePriceStart: '',
      salePriceEnd: '',
      landLevel: '',
      landHolder: '',
      industryParent: '',
      industryCategory: '',
      industryCategoryList: [],
      authority: '',
      areaSizeStart: '',
      areaSizeEnd: '',
      contractSigningDate: [],
      realStartTime: [],
      realEndTime: [],
      arrangeEndTime: [],
      arrangeStartTime: [],
      arrangeDeliveryDime: [],
      pageNum: 1,
      pageSize: 10,
      landIds: [],
      total: 0,
      multipleTable:[],
      isColMore: false,
      idArr: [],
      idPreArr: [],
      tagData: [
          {
              name:'电子监管号',
              id:'dzjg'
          },
          {
              name:'土地来源',
              id:'tdly'
          },
          {
              name:'供地方式',
              id:'gdfs'
          },
          {
              name:'成交价格',
              id:'cjjg'
          },
          {
              name:'土地级别',
              id:'tdjb'
          },
          {
              name:'行业',
              id:'hy'
          },
          {
              name:'批准单位',
              id:'pzdw'
          },
          {
              name:'实际竣工时间',
              id:'sjjg'
          },
          {
              name:'实际开工时间',
              id:'sjkg'
          },
          {
              name:'约定竣工时间',
              id:'ydjg'
          },
          {
              name:'约定开工时间',
              id:'ydkg'
          },
          {
              name:'约定交地时间',
              id:'ydjd'
          },
      ]
    };
  },
  created() {
    this.regionMap();
    //默认省市区选择
    //是否选择过
    try {
      //用户默认省市区
      this.selectedOptions[0] = getLocalStore('userinfo').province;
      this.selectedOptions[1] = getLocalStore('userinfo').city;
      this.selectedOptions[2] = getLocalStore('userinfo').county;
      this.province = getLocalStore('userinfo').province;
      this.city = getLocalStore('userinfo').city;
      this.county = getLocalStore('userinfo').county;
    } catch (error) {
      this.selectedOptions = []
      this.province = '';
      this.city = '';
      this.county = '';
    }
  },
  destroyed() {},
  mounted() {
    this.getLandUsage();
    this.getLandOfCustomerArea(0);
  },
  directives: {clickoutside},
  methods: {
    ...mapMutations(["addPath","reducePath", "addMenuList", "reduceMenuList"]),
    //展现点击上报
    getReport(recordType,modelName,clickName){
        this.axios.request({
            method: "post", 
            url: '/dms/saveUserBehaviorRecord',
            data: {
                pageName:'industryAnalysis',
                userName:getLocalStore('areainfo').userName,
                recordType:recordType,
                modelName:modelName,
                clickName:clickName,
                userId:getLocalStore('areainfo').id,
                ipAddress:getLocalStore('areainfo').ipAddress,
                userAddress:getLocalStore('areainfo').userAddress,
                ubr: getStore('reportobj')||{},
            },
        }).then((res) => {
            setStore('reportobj',res.data.data)
        })
    },
    //选择省市区
    areaHandleChange(value) {
      this.province = value[0];
      setStore("selprovince", this.province);
      this.city = value[1];
      setStore("selcity", this.city);
      this.county = value[2]
      setStore("selcounty", this.county);
      this.searchData(false)
    },
    //初始化有效数据
    getLandOfCustomerArea(type){
        this.isMoreSelect = false;
        let data = {
            province: this.province,
            city: this.city,
            county: this.county,
            userId: getLocalStore('userinfo').id,
            type: type,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            supervisionNumber: this.supervisionNumber,
            salePriceStart: this.salePriceStart,
            salePriceEnd: this.salePriceEnd,
            landUsageSuperior: this.landUsageSuperior,
            landUsage: this.landUsage,
            projectName: this.projectName,
            projectAddress: this.projectAddress,
            plotRatioUp: this.plotRatioUp,
            plotRatioDown: this.plotRatioDown,
            landSupplyMethod: this.landSupplyMethod,
            landLevel: this.landLevel,
            landHolder: this.landHolder,
            industryParent: this.industryParent,
            industryCategory: this.industryCategory,
            authority: this.authority,
            contractSigningDate: this.contractSigningDate,
            realEndTime: this.realEndTime,
            realStartTime: this.realStartTime,
            arrangeEndTime: this.arrangeEndTime,
            arrangeStartTime: this.arrangeStartTime,
            arrangeDeliveryDime: this.arrangeDeliveryDime,
            areaSizeStart: this.areaSizeStart,
            areaSizeEnd: this.areaSizeEnd,
            transferType: '',
            landIds: [],
            userSource:getLocalStore('userinfo').userSource,
            orderNum: getLocalStore("orderNum"),
            reportVersion: getLocalStore("reportVersion"),
        }
          this.axios.request({
            loading: true,
            method: "post", 
            url: '/dms/pageLandTransferByES',
              
            data:data

        }).then((res) => {

            if(type==0){
                this.validTableData = res.data.data.page.records;
                this.total = res.data.data.page.total;
            }else{
                this.invalidTableData = res.data.data.page.records;
                this.total = res.data.data.page.total;
            }
            

        })
    },
    getMoreSelect(){
        this.isMoreSelect = !this.isMoreSelect;
        if(this.activeName=='wx'){
            this.getReport(2,'invalid','moreCondition');
        }else{
            this.getReport(2,'valid','moreCondition');
        }
    },
    handleClose(e) {
        this.isMoreSelect = false;
    },
    //获取省市区
    regionMap  (){
        this.axios.request({
            loading: true,
            method: "get", 
            url: '/dms/common/regionMap',
        }).then((res) => {
            this.areaData = res.data.data;

        })
    },
    //获取土地查询条件选项
    getLandUsage(){
        this.axios.request({
            loading: true,
            method: "post", 
            url: '/dms/getLandUsage',
              
        }).then((res) => {
            this.landUsageData = res.data.data
        })
    },
    //土地用途父级change
    selectChange(val){
        this.landUsageList = this.landUsageData.landUage[val];
        this.landUsage = ''
    },
    industryChange(val){
        this.industryCategoryList = this.landUsageData.industry[val];
        this.industryCategory = ''
    },
    //查询数据
    searchData(flag){
        this.isSearch = true;
        this.isMoreSelect = false;
        let type = 0;
        this.getReport(2,'valid','searchBtn');
        type = 0;
        if(flag){
            this.pageNum = 1;
        }
        let data = {
            province: this.province,
            city: this.city,
            county: this.county,
            userId: getLocalStore('userinfo').id,
            type: type,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            supervisionNumber: this.supervisionNumber,
            salePriceStart: this.salePriceStart,
            salePriceEnd: this.salePriceEnd,
            landUsageSuperior: this.landUsageSuperior,
            landUsage: this.landUsage,
            projectName: this.projectName,
            projectAddress: this.projectAddress,
            plotRatioUp: this.plotRatioUp,
            plotRatioDown: this.plotRatioDown,
            landSupplyMethod: this.landSupplyMethod,
            landLevel: this.landLevel,
            landHolder: this.landHolder,
            industryParent: this.industryParent,
            industryCategory: this.industryCategory,
            authority: this.authority,
            contractSigningDate: this.contractSigningDate,
            realEndTime: this.realEndTime,
            realStartTime: this.realStartTime,
            arrangeEndTime: this.arrangeEndTime,
            arrangeStartTime: this.arrangeStartTime,
            arrangeDeliveryDime: this.arrangeDeliveryDime,
            areaSizeStart: this.areaSizeStart,
            areaSizeEnd: this.areaSizeEnd,
            transferType: '',
            landIds: this.landIds,
            userSource:getLocalStore('userinfo').userSource,
            orderNum: getLocalStore("orderNum"),
            reportVersion: getLocalStore("reportVersion"),
        }
          this.axios.request({
            loading: true,
            method: "post", 
            url: '/dms/pageLandTransferByES',
              
            data:data

        }).then((res) => {
            this.validTableData = res.data.data.page.records;
            this.total = res.data.data.page.total;
        })
    },
    //重置
    reset(){
            this.selectedOptions = [];
            this.province = '';
            this.city = '';
            this.county = '';
            this.supervisionNumber = '';
            this.salePriceStart = '';
            this.salePriceEnd = '';
            this.landUsageSuperior = '';
            this.landUsage = '';
            this.projectName = '';
            this.projectAddress = '';
            this.plotRatioUp = '';
            this.plotRatioDown = '';
            this.landSupplyMethod = '';
            this.landLevel = '';
            this.landHolder = '';
            this.industryParent = '';
            this.industryCategory = '';
            this.authority = '';
            this.contractSigningDate = [];
            this.realEndTime = [];
            this.realStartTime = [];
            this.arrangeEndTime = [];
            this.arrangeStartTime = [];
            this.arrangeDeliveryDime = [];
            this.areaSizeStart = '';
            this.areaSizeEnd = '';
            if(this.activeName=='wx'){
                this.getReport(2,'invalid','resetBtn');
            }else{
                this.getReport(2,'valid','resetBtn');
            }
    },
    handleSizeChange(val){
        this.pageSize = val;
        this.pageNum = 1;
        if(this.isSearch){
          this.searchData(false)
        }else{
          this.getLandOfCustomerArea(0);
        }
    },
    handleCurrentChange(val){
        this.pageNum = val;
        this.getReport(2,'valid',val);
        if(this.isSearch){
            this.searchData(false)
        }else{
          this.getLandOfCustomerArea(0);
        }
        
    },
    //展示更多筛选条件
    handleSelectionChange(val){
        let arr = [];
        val.forEach(function(e){  
            arr.push(e.urlAbbr)
        });
        this.landIds = arr;

    },
    handleSelectionChangeTag(val){
        this.getReport(2,'valid','colEdit');
        let arr = [];
        val.forEach(function(e){  
            arr.push(e.id)
        });
        this.idPreArr = arr;

    },

    getMoreCol(){
        this.isColMore = !this.isColMore;
    },
    resetTable(){
        this.$refs.multipleTable.clearSelection();
        this.getReport(2,'valid','editReset');
    },
    selectAll(){
        this.$refs.multipleTable.toggleAllSelection();
        this.getReport(2,'valid','editAll');
    },
    confirm(){
        this.idArr = this.idPreArr;
        this.getReport(2,'valid','editConfim');
        this.isColMore = !this.isColMore;
    },
     
  
  
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.tag {
  color: #646566;
  margin: 16px 0;
}
.list_box {
  width: 100%;
  height: auto;
  background: #ffffff;
}
.search-box {
  padding: 10px 24px 0;
  border-bottom: 1px solid #ebebeb;
  overflow: hidden;
  .demo-form-inline{
    float: right;
  }
  .region{
    float: right;
    cursor: pointer;
    color: #185BCE;
    line-height: 32px;
    margin-left: 10px;
  }
  .el-form-item {
    margin-bottom: 11px !important;
  }
}
.module-title {
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  float: left;
}
.el-cascader {
  line-height: 30px;
}
.main_box{
        padding: 24px;
        position: relative;
    }
    .input_box{
        width: 100%;
        height: 32px;
        margin-bottom: 16px;
        >p{
            float: left;
            line-height: 32px;
        }
        .el-input{
            float: left;
            width: 170px;
            margin-right: 10px;
        }
        .el-select{
            float: left;
            height: 32px;
            width: 155px;
            margin-right: 6px;
            margin-bottom: 12px;
            /deep/.el-input__icon{
                line-height: 32px !important;
            }
        }
        /deep/.el-input__inner{
            width: 160px;
            height: 32px !important;
            line-height: 32px !important;
        }
        .el-button{
            float: left;
            margin-left: 12px;
        }
        .push_chart{
            margin-left: 0 !important;
        }
        .more_search{
            color: #185BCE;
            cursor: pointer;
            margin-left: 4px;
        }
        .right{
            float: right;
            height: 100%;
            line-height: 32px;
            color: #185BCE;
            >p{
                float: left;
                margin-right: 16px;
                cursor: pointer;
            }
            >i{
                cursor: pointer;
            }
        }
        .select_more{
            width: 290px;
            height: 320px;
            background: #FFFFFF;
            box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
            position: absolute;
            top: 51px;
            right: 177px;
            box-sizing: border-box;
            padding: 14px 0;
            z-index: 99;
            .title{
                font-size: 16px;
                color: #323233;
                margin-bottom: 26px;
                padding-left: 11px;
                font-weight: 600;
            }
            .input{
                width: 100%;
                height: 250px;
                padding: 0px 11px;
                box-sizing: border-box;
                overflow-y: scroll;
                .input_item{
                    overflow: hidden;
                    margin-bottom: 16px;
                    .el-input{
                        float: left;
                        width: 144px;
                        /deep/.el-input__inner{
                            padding: 0 0 0 8px;
                        }
                    }
                    .el-input.small_input{
                        width: 66px;
                        margin-right: 0;
                        /deep/.el-input__inner{
                            width: 66px;
                            padding: 0 0 0 8px;
                        }
                    }
                    .el-input__inner.date_select{
                        width: 100%;
                        /deep/.el-range__icon{
                            line-height: 25px;
                        }
                        /deep/.el-range-separator{
                            line-height: 25px;
                        }
                        /deep/.el-range__close-icon{
                            line-height: 25px;
                        }
                    }
                    .el-select{
                        float: left;
                        width: 144px;
                        margin-bottom: 0;
                        /deep/.el-input__inner{
                            padding: 0 0 0 8px;
                        }
                    }
                    .item_title{
                        float: left;
                        width: 88px;
                        min-height: 32px;
                        line-height: 32px;
                        font-weight: 400;
                        color: #646566;
                    }
                    .line{
                        float: left;
                        line-height: 32px;
                        margin: 0 10px;
                        color: #646566;
                    }
                }
            }
        }
        .col_more{
            width: 214px;
            height: 300px;
            background: #FFFFFF;
            box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
            position: absolute;
            top: 56px;
            right: 5px;
            box-sizing: border-box;
            padding: 14px 0;
            z-index: 99;
            .reset{
                position: absolute;
                top: 17px;
                right: 17px;
                width: 28px;
                height: 20px;
                font-size: 14px;
                color: #185BCE;
                cursor: pointer;
            }
            .select_all{
                position: absolute;
                top: 17px;
                right: 55px;
                width: 28px;
                height: 20px;
                font-size: 14px;
                color: #185BCE;
                cursor: pointer;
            }
            .select_scroll{
                width: 100%;
                height: 200px;
                overflow-y: scroll;
                border-bottom: 1px solid #EBEEF5;
            }
            .confirm{
                position: absolute;
                bottom: 5px;
                right: 10px;
            }
            /deep/.el-table td,.el-table th.is-leaf,.el-table--border,.el-table--group {
                border: none;
                cursor: pointer;
            }
            .el-table::before {
                height: 0;
            }
            .title{
                font-size: 16px;
                color: #323233;
                margin-bottom: 20px;
                padding-left: 11px;
                font-weight: 600;
            }
            
        }
    }
    
    /deep/.el-tabs__content{
        overflow: initial !important;
    }
    /deep/.el-tabs__header{
        border-bottom: none !important;
        
    }
    /deep/.el-tabs__nav{
        border: none !important;
    }
    /deep/.el-tabs__item{
        background: rgba(50, 50, 51, 0.06);
        border: none;
    }
    /deep/.cell{
        line-height: 22px;
    }
    /deep/.el-table__cell{
        padding: 6px 0;
    }
    /deep/.el-input__inner{
        height: 32px !important;
        line-height: 32px !important;
    }
    .table_box{
        /deep/.is-scrolling-left,/deep/.is-scrolling-right,/deep/.is-scrolling-middle{
            height: 347px !important;
        }
    }
</style>